import { IonButton, IonButtons, IonCard, IonCardHeader, IonCol, IonContent, IonGrid, IonHeader, IonInput, IonItem, IonLabel, IonList, IonMenuButton, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import Api from '../../api/Api';
import img_logo from '../../assets/images/logo.png';
import { debug } from '../../Utils';
import './Login.css';


const Login: React.FC = () => {
    const history = useHistory()
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [warning, setWarning] = useState(true);

    useEffect(() => {
        debug("useEffect:localStorage=",localStorage)
    }, []);

    function signin(event: any) {
        debug("signin:localStorage.getItem('isAuth')=",localStorage.getItem('isAuth'))
        let post_data = { username: username, password: password }
        axios.post(Api + 'login', post_data)
            .then(res => {
                localStorage.setItem('isAuth', '1')
                localStorage.setItem('username', username)
                localStorage.setItem('access_token', res.data['access_token'])
                axios.get(Api + 'user?filter=username||$eq||'+username).
                    then(
                        res => {
                            localStorage.setItem('customer_id',res.data[0].customer.id)
                            window.location.href = '/'
                        }
                    )
                    .catch(function (error) {
                        if (error.response) {
                            setWarning(false)
                        }
                    });
            })
            .catch(function (error) {
                if (error.response) {
                    setWarning(false)
                }
            });
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color={'primary'}>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>Login</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>

                <IonGrid className='grid-max-width'>
                    <IonRow>
                        <IonCol>
                            <IonCard>
                                <form onSubmit={signin}>
                                    <IonCardHeader className='ion-text-center'>
                                        <img className='img-logo' src={img_logo}></img>
                                    </IonCardHeader>
                                    <IonList>
                                        <IonItem>
                                            <IonLabel color={'primary'}>Username:</IonLabel>
                                            <IonInput value={username} placeholder="" onIonChange={e => setUsername(e.detail.value!)}></IonInput>
                                        </IonItem>

                                        <IonItem>
                                            <IonLabel color={'primary'}>Password:</IonLabel>
                                            <IonInput value={password} placeholder="" onIonChange={e => setPassword(e.detail.value!)} type="password"></IonInput>
                                        </IonItem>
                                    </IonList>

                                    <IonItem hidden={warning}>
                                        <IonLabel color={'danger'}>ชื่อเข้าใช้งาน หรือรหัสผ่าน ไม่ถูกต้อง</IonLabel><br />
                                    </IonItem>
                                    <div className='div-btn-signin'>
                                        <IonButton onClick={signin} expand='block' fill='solid' shape='round' size='small'>Sign In</IonButton>
                                    </div>
                                </form>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonPage>

    );
};

export default Login;
