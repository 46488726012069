import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner';
import { IonButton, IonButtons, IonCard, IonCol, IonContent, IonDatetime, IonDatetimeButton, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonMenuButton, IonPage, IonRow, IonSelect, IonSelectOption, IonTitle, IonToggle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import axios from 'axios';
import { addCircle } from 'ionicons/icons';
import { default as moment, default as Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import Api from '../../api/Api';
import { debug } from '../../Utils';
import './TaskJob.css';
import Modal from 'react-modal';


const TaskJobCrud: React.FC = () => {
    let empty = {
        id: "",
        date: new Date(),
        product: { id: "" },
        device: { id: "" },
        crBy: {},
        crDate: new Date(),
        updBy: {},
        updDate: new Date(),
        startStop: false
    }

    const [user, setUser] = useState({})
    const [products, setProducts] = useState<any[]>([])
    const [devices, setDevices] = useState<any[]>([])
    const [data, setData] = useState<any>(empty);
    const [selectedDevice, setSelectedDevice] = useState("")
    const [selectedProduct, setSelectedProduct] = useState("")
    const [warning, setWarning] = useState(false)
    const [selectedDate, setSelectedDate] = useState(moment().format())

    useEffect(() => {
        load_data(localStorage.getItem('edit_id'))
    }, [selectedDevice, selectedProduct]);

    useIonViewDidEnter(() => {
        if (localStorage.getItem('edit_id') === null) {
            const now = new Date()
            debug("customer_id=", localStorage.getItem('customer_id'))
            data.id = format_id(now)
            data.date = now
            // setData(empty)

            return
        }

        debug("useIonViewDidEnter:localStorage['edit_id']=", localStorage.getItem('edit_id'))

        let id = localStorage.getItem('edit_id')
        axios.get(Api + `TaskJob/${id}`)
            .then(res => {
                setData(res.data)
                setSelectedDevice(res.data.device.id)
                setSelectedProduct(res.data.product.id)
                setSelectedDate(res.data.date)
            })
        debug("useIonViewDidEnter:data=", data)
        localStorage.removeItem('edit_id')
    });




    const openScanner = async () => {
        debug("before scane:", JSON.stringify(data))
        const barcode_data = await BarcodeScanner.scan();
        debug(`Barcode data: ${barcode_data.text}`);
        data.device = devices.filter(obj => obj.id == barcode_data.text)[0]
        setSelectedDevice(barcode_data.text)
        setData(data)
        debug('openScanner:data=', data)
    };

    function load_data(id: any) {
        debug("load_data:id=", id)
        setData(empty)

        axios.get(Api + `User?filter=username||$eq||${localStorage.getItem('username')}`)
            .then(res => {
                setUser(res.data[0])
            });
        axios.get(Api + `Device?filter=customerId||$eq||${localStorage.getItem('customer_id')}&sort=label,ASC`)
            .then(res => {
                setDevices(res.data)
            });
        axios.get(Api + `Product`)
            .then(res => {
                setProducts(res.data)
            });
        if (id != undefined && id != "taskjob_crud" && id != null) {
            axios.get(Api + `TaskJob/${id}`)
                .then(res => {
                    setData(res.data)
                    setSelectedDevice(res.data.device.id)
                    setSelectedProduct(res.data.product.id)
                })
        }
    }




    function format_id(now: Date) {
        Moment.locale('th-TH')
        // debug("customer_id=", localStorage.getItem('customer_id'))
        let prefix = "KK"
        if (localStorage.getItem('customer_id') == "dc199d90-ec27-11ee-8b42-4507d31c1af1"){
            prefix = "KM"
        }
        return prefix + "-" + Moment(now).format('YYYYMMDD-HHmmss')
    }


    async function on_submit() {
        debug("on_submit:data=", data)

        if (data.product.id === "") {
            setWarning(true)
            debug("product is null")
            return
        }
        if (data.device.id === "") {
            setWarning(true)
            debug("device is null")
            return
        }

        data.crBy = user
        data.crDate = new Date()
        data.updBy = user
        data.updDate = new Date()

        if (data.startStop) {
            await axios.get(Api + `TaskJob?join=device&filter=device.id||$eq||${data.device.id}&filter=startStop||$eq||true`)
                .then(res => {
                    // setTaskJobList(res.data)
                    debug("on_submit:find_taskjob_status_on=", res.data)
                    res.data.forEach((tj: any) => {
                        tj.startStop = false
                        axios.post(Api + 'TaskJob', tj).
                            then(res => {
                                debug("startStop=false", tj.id)
                            });
                    });
                })
            // .catch(err => {
            //     debug("on_submit:find_taskjob_status_on=", "error")
            // });
            await axios.post(Api + 'TaskJob', data).
                then(res => {
                    localStorage.setItem('device_id', data.device.id)
                    localStorage.setItem('date', moment(data.date).format())
                    window.location.href = '/taskjob_list'
                });
        }
        else {
            await axios.post(Api + 'TaskJob', data).
                then(res => {
                    localStorage.setItem('device_id', data.device.id)
                    localStorage.setItem('date', moment(data.date).format())
                    window.location.href = '/taskjob_list'
                });
        }
    }

    function format_date(obj: any) {
        try {
            var date = new Date(obj.date)
            return date.toLocaleString('th-TH')
        } catch (error) {
        }
        return ""
    }

    const on_add = () => {
        init()
    }

    function init() {
        debug("init")
        window.location.reload()
    }

    function on_delete(obj: any) {
        setSelectedDate(moment(obj).format())
    }

    // ------------------------ Modal ------------------------ //
    Modal.setAppElement('#root');
    const [modalIsOpen, setIsOpen] = React.useState(false);
    function openModal() {
        setIsOpen(true);
    }
    function afterOpenModal() { }
    function closeModal() {
        setIsOpen(false);
    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            // borderRadius: '50px'
        },
    };

    function on_date_selected(obj: any){
        setSelectedDate(moment(obj).format())
        data.date = moment(obj).format()
    }

    const [showLoading, setShowLoading] = useState(false);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color={'primary'}>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>สร้างใบงาน</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonLoading
                cssClass='my-custom-class'
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Please wait...'}
                duration={1}
            />

            <IonContent>
                {/* <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonButton expand='full' fill='solid' shape='round' color='warning' onClick={openScanner}>
                                <IonLabel>สแกน</IonLabel>
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid> */}

                <IonCard>
                    <IonItem color='primary'>
                        <IonLabel>ใบงาน</IonLabel>
                        <IonButton onClick={() => on_add()}>
                            <IonIcon color='success' slot="icon-only" icon={addCircle}></IonIcon>
                        </IonButton>
                    </IonItem>

                    <IonGrid fixed={true}>
                        <IonRow>
                            <IonCol size='3'><IonLabel color='primary'>เลขที่:</IonLabel></IonCol>
                            <IonCol><IonLabel color='dark'>{data.id}</IonLabel></IonCol>
                        </IonRow>
                        <IonRow class="ion-align-items-center">
                            <IonCol size='3'><IonLabel color='primary'>วันเวลา:</IonLabel></IonCol>
                            <IonCol>
                                <IonDatetimeButton style={{display: 'block'}} onClick={openModal} datetime="dt_tc"></IonDatetimeButton>
                                <IonDatetime hidden={true} presentation='date' onIonChange={(e) => on_date_selected(e.target.value)} value={selectedDate} locale='th-TH' id="dt_tc"></IonDatetime>
                                <Modal
                                    isOpen={modalIsOpen}
                                    onAfterOpen={afterOpenModal}
                                    onRequestClose={closeModal}
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                    overlayClassName="Overlay"
                                >
                                    <IonDatetime presentation='date' onIonChange={(e) => on_date_selected(e.target.value)} value={selectedDate} locale='th-TH'></IonDatetime>
                                </Modal>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCard>

                <IonCard>
                    <IonItem color='primary'>
                        <IonLabel>เครื่องจักร</IonLabel>
                    </IonItem>
                    <IonList>
                        <IonSelect interface="action-sheet" placeholder="เลือกเครื่องจักร" value={selectedDevice}
                            onIonChange={(e) => data.device = devices.filter(obj => obj.id == e.detail.value)[0]}>
                            {devices.map(obj => (
                                <IonSelectOption key={obj.id} value={obj.id}>{obj.label}</IonSelectOption>
                            ))}
                        </IonSelect>
                    </IonList>
                </IonCard>

                <IonCard>
                    <IonItem color='primary'>
                        <IonLabel>ประเภทมะขาม</IonLabel>
                        <IonToggle color="success" checked={data.startStop}
                            onIonChange={(e) => data.startStop = e.target.checked}>
                        </IonToggle>
                    </IonItem>
                    <IonList>
                        {/* {do_render(data) &&  */}
                        <IonSelect interface="action-sheet" placeholder="เลือกประเภทมะขาม" value={selectedProduct}
                            onIonChange={(e) => data.product = products.filter(obj => obj.id == e.detail.value)[0]}>
                            {products.map(obj => (
                                <IonSelectOption key={obj.id} value={obj.id}>{obj.name}</IonSelectOption>
                            ))}
                        </IonSelect>
                        {/* }  */}
                    </IonList>
                </IonCard>

                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonButton expand='full' fill='solid' shape='round' color='success'
                                onClick={() => on_submit()}>
                                <IonLabel>บันทึก</IonLabel>
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonPage>
    );
}
export default TaskJobCrud;

