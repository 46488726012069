import { IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonDatetime, IonDatetimeButton, IonGrid, IonHeader, IonLoading, IonMenuButton, IonPage, IonRow, IonTitle, IonToolbar, useIonAlert, useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react';
import axios from 'axios';
import { forceUpdate } from 'ionicons/dist/types/stencil-public-runtime';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import Api from '../../api/Api';
import { debug } from '../../Utils';
import './TaskJob.css';

const TaskDeviceList: React.FC = () => {
    const [deviceList, setDeviceList] = useState<any>([])
    const [selectedDate, setSelectedDate] = useState(moment().format())
    const [refresh, setRefresh] = useState(0)
    const myRef = useRef<any>(null)

    useEffect(() => {
        debug("useEffect:running=", "TaskDeviceList")
        // openModal()
        let date = localStorage.getItem('date')
        if (date === null || date === undefined) {
            date = selectedDate
        }
        load_data(date)
        // on_date_selected(date)
        // closeModal()
        const intervalId = setInterval(() => {
            handleForceupdateMethod()
          }, 1000);
        
        return () => clearInterval(intervalId);

    }, [refresh]);

    // useIonViewDidEnter(() => {
    //     // debug("useIonViewDidEnter=", "TaskDeviceList")
    //     // openModal()
    //     // let date = localStorage.getItem('date')
    //     // if (date === null || date === undefined) {
    //     //     date = selectedDate
    //     // }
    //     // load_data(date)
    // });

    function get_date_start_end(obj: any) {
        const date = moment(obj).format()
        debug("get_date_start_end:format=", date)
        const now_format = date.split('T')[0]
        const str_s_hour = '00:00'
        const str_e_hour = '23:59'
        const sdate = now_format + ' ' + str_s_hour
        const edate = now_format + ' ' + str_e_hour
        return { sdate: sdate, edate: edate }
    }

    function load_data(date: any) {
        setShowLoading(true)

        debug("load_data:selectedDate=", date)
        debug("Api", Api + `Device?filter=customerId||$eq||${localStorage.getItem('customer_id')}&sort=label,ASC`)
        axios.get(Api + `Device?filter=customerId||$eq||${localStorage.getItem('customer_id')}&sort=label,ASC`)
            .then(res => {
                res.data.forEach(async (dv: any, index: any) => {
                    //find_taskjob(dv, index, date)
                    let sum = 0
                    const qdate = get_date_start_end(date)
                    debug("device==>id=", dv.id)
                    axios.get(Api + `TaskJob?join=device&filter=device.id||$eq||${dv.id}&filter=date||$between||${qdate.sdate},${qdate.edate}&sort=date,DESC`)
                        .then(result => {
                            // debug("find_taskjob:index=", index)
                            // debug("find_taskjob:data=", res.data)
                            // debug("find_taskjob:lenght=", result.data.length)
                            dv.count = result.data.length
                        });
                    // setDeviceList(res.data)
                });
                debug("res.data=", res.data)
                setDeviceList(res.data)
                setShowLoading(false)
                setSelectedDate(date)
                // setRefresh(prev=>prev+1)
            });

        if (myRef.current !== null) {
            debug("myRef=", myRef)
            myRef.current.click()
        }
    }

    function on_date_selected(value: any) {
        localStorage.setItem('date', value)
        debug("on_date_selected:value=", value)
        const str_s_hour = '00:00'
        const str_e_hour = '23:59'
        const now_format = value.split('T')[0]
        const sdate = now_format + ' ' + str_s_hour
        const edate = now_format + ' ' + str_e_hour
        setSelectedDate(value)
        load_data(value)
        setRefresh(prev => prev + 1)
    }

    const [presentAlert] = useIonAlert();
    const [handlerMessage, setHandlerMessage] = useState('');
    const [roleMessage, setRoleMessage] = useState('');
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            // borderRadius: '50px'
        },
    };

    Modal.setAppElement('#root');
    const [modalIsOpen, setIsOpen] = React.useState(false);
    function openModal() {
        setIsOpen(true);
    }
    function afterOpenModal() { }
    function closeModal() {
        setIsOpen(false);
    }

    function on_device_select(id: any) {
        localStorage.setItem('device_id', id)
        window.location.href = '/taskjob_list'
    }

    const [showLoading, setShowLoading] = useState(false);

    // debug("myArray:", myArray)
    debug("deviceList=", deviceList)

    function useForceUpdate() {
        let [value, setState] = useState(true);
        return () => setState(!value);
    }
    const handleForceupdateMethod = useForceUpdate();

    // let sum = 0
    // deviceList.forEach((value: any) => {
    //     debug("sum=", value.count)
    // });

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color={'primary'}>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>เลือกตู้อบ</IonTitle>
                </IonToolbar>
            </IonHeader>

            {/* <IonLoading
                cssClass='my-custom-class'
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Please wait...'}
                duration={1}
            /> */}

            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            {/* <IonButtons onClick={handleForceupdateMethod}>Test</IonButtons> */}
                            <IonDatetimeButton onClick={openModal} datetime="dt_td_list"></IonDatetimeButton>
                            <IonDatetime hidden={true} presentation='date' onIonChange={(e) => on_date_selected(e.target.value)} value={selectedDate} locale='th-TH' id="dt_td_list"></IonDatetime>
                            <Modal
                                isOpen={modalIsOpen}
                                onAfterOpen={afterOpenModal}
                                onRequestClose={closeModal}
                                style={customStyles}
                                contentLabel="Example Modal"
                                overlayClassName="Overlay"
                            >
                                <IonDatetime presentation='date' onIonChange={(e) => on_date_selected(e.target.value)} value={selectedDate} locale='th-TH'></IonDatetime>
                            </Modal>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                <IonGrid>
                    <IonRow>
                        {deviceList.map((obj: any, index: number) => (
                            <IonCol key={obj.id} size='6'>
                                <IonCard color="tertiary" onMouseDown={(e) => on_device_select(obj.id)}>
                                    <IonCardHeader>
                                        <IonCardTitle><span style={{ color: 'FloralWhite' }}>{obj.label}</span></IonCardTitle>
                                        <br />
                                        {/* <IonCardSubtitle>Card Subtitle</IonCardSubtitle> */}
                                    </IonCardHeader>

                                    <IonCardContent>
                                        งาน: {obj.count} รายการ
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        ))}
                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonPage>

    );
}

export default TaskDeviceList;


