import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote
} from '@ionic/react';

import { clipboardOutline, clipboardSharp, documentTextOutline, documentTextSharp, logOutOutline, logOutSharp } from 'ionicons/icons';
import { useHistory, useLocation } from 'react-router-dom';
import './Menu.css';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  // {
  //   title: 'Profile',
  //   url: '/page/profile',
  //   iosIcon: mailOutline,
  //   mdIcon: mailSharp
  // },
  // {
  //   title: 'Barcode',
  //   url: '/barcode',
  //   iosIcon: barcodeOutline,
  //   mdIcon: barcodeSharp
  // },
  // {
  //   title: 'ใบงานประจำวัน',
  //   url: '/taskjob_list',
  //   iosIcon: clipboardOutline,
  //   mdIcon: clipboardSharp
  // },
  {
    title: 'ใบงานประจำวัน',
    url: '/taskjob_device',
    iosIcon: clipboardOutline,
    mdIcon: clipboardSharp
  },
  {
    title: 'บันทึกใบงาน',
    url: '/taskjob_crud',
    iosIcon: documentTextOutline,
    mdIcon: documentTextSharp
  },
];

const labels = ['Family', //'Friends',
  // 'Notes', 'Work', 'Travel', 'Reminders'
];

const Menu: React.FC = () => {
  const location = useLocation();
  const history = useHistory()

  function signout() {
    localStorage.clear()
    console.log(location.pathname)
    window.location.href = '/'
  }

  return (localStorage.getItem('isAuth')==="1") ? (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>SMSN</IonListHeader>
          <IonNote>argimon@smartsensedesign.net</IonNote>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

        <IonList id="signout-list">
          <IonMenuToggle key={appPages.length + 1} autoHide={false}>
            <IonItem className={location.pathname === '/login' ? 'selected' : ''} routerLink={'/login'} onClick={signout} routerDirection="none" lines="none" detail={false}>
              <IonIcon slot="start" ios={logOutOutline} md={logOutSharp} />
              <IonLabel>Sign Out</IonLabel>
            </IonItem>
          </IonMenuToggle>
        </IonList>

        {/* <IonList id="labels-list">
          <IonListHeader>Labels</IonListHeader>
          {labels.map((label, index) => (
            <IonItem lines="none" key={index}>
              <IonIcon slot="start" icon={bookmarkOutline} />
              <IonLabel>{label}</IonLabel>
            </IonItem>
          ))}
        </IonList> */}

      </IonContent>
    </IonMenu>
  ) : (<span hidden={true}></span>);
};

export default Menu;
