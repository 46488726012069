import { IonButton, IonButtons, IonCard, IonCardTitle, IonCol, IonContent, IonDatetime, IonDatetimeButton, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonLoading, IonMenuButton, IonPage, IonRow, IonTitle, IonToggle, IonToolbar, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import axios from 'axios';
import { pencil, trashBin } from 'ionicons/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router';
import Api from '../../api/Api';
import { debug } from '../../Utils';
import './TaskJob.css';

const TaskJobList: React.FC = () => {
    const history = useHistory()
    const [taskJobList, setTaskJobList] = useState<any>([])
    const [selectedDate, setSelectedDate] = useState(moment().format())

    useEffect(() => {
        debug("useEffect:running=", "TaskJobList")
        let date = localStorage.getItem('date')
        if (date !== null) {
            debug("do date")
            load_data(date)
        }
        else {
            debug("do selectedDate")
            load_data(selectedDate)
        }
    }, []);

    useIonViewDidEnter(() => {
        debug("useIonViewDidEnter:TaskJobList", JSON.stringify(taskJobList))
        debug("useIonViewDidEnter:selectedDate", selectedDate)
        // setSelectedDate(selectedDate)

        let date = localStorage.getItem('date')
        if (date !== null) {
            debug("do date")
            load_data(date)
        }
        else {
            debug("do selectedDate")
            load_data(selectedDate)
        }
    });


    function get_date_start_end(obj: any) {
        const date = moment(obj).format()
        debug("get_date_start_end:format=", date)
        const now_format = date.split('T')[0]
        const str_s_hour = '00:00'
        const str_e_hour = '23:59'
        const sdate = now_format + ' ' + str_s_hour
        const edate = now_format + ' ' + str_e_hour

        return { sdate: sdate, edate: edate }
    }

    function load_data(date: any) {
        setShowLoading(true)
        debug("load_data:selectedDate=", date)
        let device_id = localStorage.getItem("device_id")
        debug("load_data:device_id", device_id)
        const qdate = get_date_start_end(date)
        axios.get(Api + `TaskJob?join=device&filter=device.id||$eq||${device_id}&filter=date||$between||${qdate.sdate},${qdate.edate}&sort=date,DESC`)
            .then(res => {
                // setTaskJobList(res.data)
                debug("find_taskjob=", res.data)
                setTaskJobList(res.data)
                setSelectedDate(date)
                setShowLoading(false)
            });
    }

    async function on_date_selected(value: any) {
        localStorage.setItem('date', value)
        debug("on_date_selected:value=", value)
        const str_s_hour = '00:00'
        const str_e_hour = '23:59'
        const now_format = value.split('T')[0]
        const sdate = now_format + ' ' + str_s_hour
        const edate = now_format + ' ' + str_e_hour
        setSelectedDate(value)

        let device_id = localStorage.getItem("device_id")
        const qdate = get_date_start_end(value)
        axios.get(Api + `TaskJob?join=device&filter=device.id||$eq||${device_id}&filter=date||$between||${qdate.sdate},${qdate.edate}&sort=date,DESC`)
            .then(res => {
                debug("find_taskjob=", res.data)
                setTaskJobList(res.data)
            });
    }

    function on_delete(obj: any) {
        const qdate = get_date_start_end(selectedDate)
        axios.delete(Api + 'TaskJob/' + obj.id)
            .then(res => {
                let date = localStorage.getItem('date')
                if (date !== null) {
                    debug("do date")
                    load_data(date)
                }
                else {
                    debug("do selectedDate")
                    load_data(selectedDate)
                }
            });
    }

    function on_edit(obj: any) {
        localStorage.setItem('edit_id', obj)
        debug("on_edit:localStorage=", JSON.stringify(localStorage))
        history.push('/taskjob_crud')
    }

    const [presentAlert] = useIonAlert();
    const [handlerMessage, setHandlerMessage] = useState('');
    const [roleMessage, setRoleMessage] = useState('');



    Modal.setAppElement('#root');
    const [modalIsOpen, setIsOpen] = React.useState(false);
    function openModal() {
        setIsOpen(true);
    }
    function afterOpenModal() { }
    function closeModal() {
        setIsOpen(false);
    }
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            // borderRadius: '50px'
        },
    };

    const [showLoading, setShowLoading] = useState(false);

    async function on_startStop(data: any, status: boolean){
        data.startStop = status
        if (status) {
            await axios.get(Api + `TaskJob?join=device&filter=device.id||$eq||${data.device.id}&filter=startStop||$eq||true`)
                .then(res => {
                    // setTaskJobList(res.data)
                    debug("on_submit:find_taskjob_status_on=", res.data)
                    res.data.forEach((tj: any) => {
                        tj.startStop = false
                        axios.post(Api + 'TaskJob', tj).
                            then(res => {
                                debug("startStop=false", tj.id)
                            });
                    });
                })
            // .catch(err => {
            //     debug("on_submit:find_taskjob_status_on=", "error")
            // });
            await axios.post(Api + 'TaskJob', data).
                then(res => {
                    localStorage.setItem('device_id', data.device.id)
                    localStorage.setItem('date', moment(data.date).format())
                    // window.location.href = '/taskjob_list'
                });
        }
        else {
            await axios.post(Api + 'TaskJob', data).
                then(res => {
                    localStorage.setItem('device_id', data.device.id)
                    localStorage.setItem('date', moment(data.date).format())
                    // window.location.href = '/taskjob_list'
                });
        }
        load_data(selectedDate)
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar color={'primary'}>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle>รายการใบงาน</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonLoading
                cssClass='my-custom-class'
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Please wait...'}
                duration={1}
            />

            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonDatetimeButton onClick={openModal} datetime="datetime"></IonDatetimeButton>
                            <IonDatetime hidden={true} presentation='date' onIonChange={(e) => on_date_selected(e.target.value)} value={selectedDate} locale='th-TH' id="datetime"></IonDatetime>
                            <Modal
                                isOpen={modalIsOpen}
                                onAfterOpen={afterOpenModal}
                                onRequestClose={closeModal}
                                style={customStyles}
                                contentLabel="Example Modal"
                                overlayClassName="Overlay"
                            >
                                <IonDatetime presentation='date' onIonChange={(e) => on_date_selected(e.target.value)} value={selectedDate} locale='th-TH'></IonDatetime>
                            </Modal>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                {taskJobList.map((obj: any, index: number) => (
                    <IonCard key={obj.id}>
                        <IonCardTitle>
                            <IonItem color='primary'>
                                <IonLabel>เลขที่: <span style={{ color: 'yellow' }}>{obj.id}</span></IonLabel>
                                <IonButton onClick={() => on_edit(obj.id)}>
                                    <IonIcon color='warning' slot="icon-only" icon={pencil}></IonIcon>
                                </IonButton>
                                <IonButton
                                    onClick={() =>
                                        presentAlert({
                                            header: 'ยืนยันการลบข้อมูล!',
                                            buttons: [
                                                {
                                                    text: 'ยกเลิก',
                                                    role: 'cancel',
                                                    handler: () => {
                                                        setHandlerMessage('Alert canceled');
                                                    },
                                                },
                                                {
                                                    text: 'ตกลง',
                                                    role: 'confirm',
                                                    handler: () => {
                                                        setHandlerMessage('Alert confirmed');
                                                        on_delete(obj)
                                                    },
                                                },
                                            ],
                                            onDidDismiss: (e: CustomEvent) => setRoleMessage(`Dismissed with role: ${e.detail.role}`),
                                        })
                                    }>
                                    <IonIcon color='danger' slot="icon-only" icon={trashBin}></IonIcon>
                                </IonButton>
                            </IonItem>
                        </IonCardTitle>

                        <IonGrid>
                            <IonRow>
                                <IonCol size='4'><IonLabel color='primary'>วันเวลา:</IonLabel></IonCol>
                                <IonCol><IonLabel>{new Date(obj.date).toLocaleString('th-TH')}</IonLabel></IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size='4'><IonLabel color='primary'>เครื่องจักร:</IonLabel></IonCol>
                                <IonCol><IonLabel>{obj.device.label}</IonLabel></IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size='4'><IonLabel color='primary'>ประเภทมะขาม:</IonLabel></IonCol>
                                <IonCol><IonLabel>{obj.product.name}</IonLabel></IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size='4'><IonLabel color='primary'>สถานะ เปิด/ปิด:</IonLabel></IonCol>
                                <IonCol>
                                    {/* <IonToggle disabled={true} style={{ opacity: 1 }} color="success" checked={obj.startStop}></IonToggle> */}
                                    <IonToggle color="success" checked={obj.startStop}
                                        onIonChange={(e)=> on_startStop(obj, e.target.checked)}>
                                    </IonToggle>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonCard>
                ))}

            </IonContent>
        </IonPage>

    );
}

export default TaskJobList;


