import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import Menu from './components/Menu';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* My Import */
import { debug } from './Utils';
import { useState, useEffect } from 'react';
import TaskJobList from './components/taskjob/TaskJobList';
import Login from './components/login/Login';
import TaskJobCrud from './components/taskjob/TaskJobCrud';
import TaskDeviceList from './components/taskjob/TaskDeviceList';

setupIonicReact();

const App: React.FC = () => {
  const [isAuth, setIsAuth] = useState(false);
  useEffect(() => {
    var strAuth = localStorage.getItem('isAuth')
    var flag = ((strAuth == "1") ? true : false);
    setIsAuth(flag)
    debug('App.tsx:isAuth=', isAuth)
  }, []);

  return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          {/* {isAuth ? <Menu></Menu> : } */}
          <Menu></Menu>
          <IonRouterOutlet id="main">
            <Route
              exact
              path="/"
              render={(props) => {
                return isAuth ? <TaskDeviceList /> : <Login />;
              }}
            />
            <Route
              exact
              path="/login"
              render={(props) => {
                return isAuth ? <TaskJobList /> : <Login />;
              }}
            />
            <Route
              exact
              path="/taskjob_crud"
              render={(props) => {
                return isAuth ? <TaskJobCrud /> : <Login />;
              }}
            />
            <Route
              exact
              path="/taskjob_list"
              render={(props) => {
                return isAuth ? <TaskJobList /> : <Login />;
              }}
            />
            <Route
              exact
              path="/taskjob_device"
              render={(props) => {
                return isAuth ? <TaskDeviceList /> : <Login />;
              }}
            />
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
